// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--8bAT5";
export var box__s8DdK = "PlasmicTopSection-module--box__s8DdK--vTL3L";
export var box__xsLUe = "PlasmicTopSection-module--box__xsLUe--10QwY";
export var box__a58L6 = "PlasmicTopSection-module--box__a58L6--2oepq";
export var box__fuyVh = "PlasmicTopSection-module--box__fuyVh--3-MZf";
export var box__qg4E = "PlasmicTopSection-module--box__qg4E--JKtOf";
export var box___4Qeq2 = "PlasmicTopSection-module--box___4Qeq2--17M6b";
export var linkButton__l0HKw = "PlasmicTopSection-module--linkButton__l0HKw--12071";
export var linkButton__r0FTr = "PlasmicTopSection-module--linkButton__r0FTr--4k4LL";
export var box__b8Fu7 = "PlasmicTopSection-module--box__b8Fu7--awSgR";
export var box__sgF64 = "PlasmicTopSection-module--box__sgF64--6B87r";
export var img = "PlasmicTopSection-module--img--22FGd";