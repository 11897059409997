// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--pXa-D";
export var root__long = "PlasmicFeatureCard-module--root__long--Z3-XW";
export var box__jKcSb = "PlasmicFeatureCard-module--box__jKcSb--3Swmb";
export var box__ilVR = "PlasmicFeatureCard-module--box__ilVR--2hp1V";
export var svg__y7EsV = "PlasmicFeatureCard-module--svg__y7EsV--2T4LA";
export var box__leuwx = "PlasmicFeatureCard-module--box__leuwx--2rmpM";
export var box__long__leuwxfN2P = "PlasmicFeatureCard-module--box__long__leuwxfN2P--cftz7";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--ikjab";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--qbOZz";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--2x1ry";
export var box__cm2Qg = "PlasmicFeatureCard-module--box__cm2Qg--3ghO-";
export var box__long__cm2QgfN2P = "PlasmicFeatureCard-module--box__long__cm2QgfN2P--2RVxX";
export var svg__apxOb = "PlasmicFeatureCard-module--svg__apxOb--1QeGm";